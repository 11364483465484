import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { environment } from '@environments/environment';
import { ToastService } from '@services/toast.service';
import queryString from 'query-string';

import { LoaderService } from './loader.service';

interface MapGeocoderResponse {
  status: google.maps.GeocoderStatus;
  results: google.maps.GeocoderResult[];
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private toastService: ToastService
  ) {}

  async getCurrentLocation() {
    try {
      await this.loader.show();
      const position = await Geolocation.getCurrentPosition({
        timeout: 8000,
      });

      this.loader.hide();
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    } catch (error) {
      this.loader.hide();
      this.toastService.show('Could not determined your location, sorry.');
      throw error;
    }
  }

  async getAddress(lat, long) {
    try {
      const response = await firstValueFrom(
        this.http.get<any>(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&language=en&key=${environment.googleMapsKey}`
        )
      );
      const place = response.results ? response.results[0] : null;
      if (place && place['formatted_address']) {
        if (place.address_components.length < 2) {
          return place['formatted_address'];
        }
        let customAddress = '';
        for (let i = 1; i < place.address_components.length; i++) {
          const component = place.address_components[i];
          if (
            component.types[0] === 'locality' ||
            component.types[0] === 'administrative_area_level_1' ||
            component.types[0] === 'country'
          ) {
            customAddress =
              customAddress + place.address_components[i].long_name + ', ';
          }
        }
        if (customAddress.charAt(customAddress.length - 1) === ' ') {
          customAddress = customAddress.substr(0, customAddress.length - 2);
        }
        return customAddress;
      }
      return '';
    } catch (error) {
      throw error;
    }
  }

  async getImageLocationAddress(image) {
    try {
      const { latitude, longitude } = await this.getImageLocationCoordinates(
        image
      );
      return this.getAddress(latitude, longitude);
    } catch (error) {
      return '';
    }
  }

  async getImageLocationCoordinates(image) {
    if (image.location) {
      return image.location as { latitude: number; longitude: number };
    }
    return this.getCurrentLocation();
  }

  async getLocationAddresses() {
    try {
      const { latitude, longitude } = await this.getCurrentLocation2();
      return this.getAddressesFromLocation({ lat: latitude, long: longitude });
    } catch (error) {
      return Promise.reject();
    }
  }

  async getLocationAddress2() {
    try {
      const { latitude, longitude } = await this.getCurrentLocation2();
      return this.getAddress2(latitude, longitude);
    } catch (error) {
      return Promise.reject();
    }
  }

  async getCurrentLocation2() {
    const position = await Geolocation.getCurrentPosition({
      timeout: 8000,
      maximumAge: 3.6e6, // 1h
    });
    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  }

  async getAddressesFromLocation(location: string): Promise<string[]>;
  async getAddressesFromLocation(location: {
    lat: number;
    long: number;
  }): Promise<string[]>;
  async getAddressesFromLocation(location: any): Promise<string[]> {
    const { results } = await firstValueFrom(
      this.http.get<MapGeocoderResponse>(
        `https://maps.googleapis.com/maps/api/geocode/json?${queryString.stringify(
          {
            language: 'en',
            key: environment.googleMapsKey,
            address: typeof location === 'string' ? location : undefined,
            latlng:
              typeof location === 'object'
                ? `${location.lat},${location.long}`
                : undefined,
          }
        )}`
      )
    );

    const componentsFormat = [
      ['locality', 'country'],
      ['administrative_area_level_2', 'country'],
      ['administrative_area_level_1', 'country'],
      ['locality', 'administrative_area_level_1', 'country'], // legacy format
      ['country'],
    ];
    const componentsToKeep = Object.keys(
      componentsFormat.reduce((acc, components) => {
        components.forEach((component) => {
          acc[component] = true;
        });
        return acc;
      }, {})
    );

    if (!results || !results[0]?.address_components) return [];

    const place = results[0];

    const components: Record<string, string> = {};
    place.address_components.forEach((component) => {
      if (!component.types.some((type) => componentsToKeep.includes(type)))
        return;
      components[component.types[0]] = component.long_name;
    });

    return [
      ...new Set( // remove duplicates, for instance NY is a locality and a state
        componentsFormat
          .map((componentsOrder) => {
            return componentsOrder
              .map((componentName) => components[componentName])
              .filter((component) => !!component);
          })
          .filter(
            (components, i) => components.length === componentsFormat[i].length
          )
          .map((component) => component.join(', '))
      ),
    ];
  }

  async getAddress2(lat, long) {
    const { results } = await firstValueFrom(
      this.http.get<MapGeocoderResponse>(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&language=en&key=${environment.googleMapsKey}`
      )
    );

    if (!results) return '';

    const place = results[0];

    if (place.formatted_address) {
      if (place.address_components.length < 2) {
        return place.formatted_address;
      }
      let customAddress = '';
      for (let i = 1; i < place.address_components.length; i++) {
        const component = place.address_components[i];
        if (
          component.types[0] === 'locality' ||
          component.types[0] === 'administrative_area_level_1' ||
          component.types[0] === 'country'
        ) {
          customAddress =
            customAddress + place.address_components[i].long_name + ', ';
        }
      }
      if (customAddress.charAt(customAddress.length - 1) === ' ') {
        customAddress = customAddress.substr(0, customAddress.length - 2);
      }
      return customAddress;
    }
    return '';
  }
}
